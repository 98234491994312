import '../styles/main.scss';
import { faHome, faLeft } from '@fortawesome/pro-regular-svg-icons';
import { Button, Heading, Image, Text } from '@quality24/design-system';
import classNames from 'classnames';
import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';

import SEO from '../components/containers/SEO';
import MainTemplate from '../components/templates/MainTemplate';
import SquareGraphic from '../assets/graphic-1.inline.svg';
import image from '../assets/404.svg';

import * as styles from './404.module.scss';

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <MainTemplate>
      <div className={classNames(styles.wrapper, 'container my-5')}>
        <Image
          className={classNames(styles.image, 'mb-5')}
          src={image}
          alt="Ilustração de uma mulher trabalhando no computador"
          style={{ maxWidth: '17.5rem' }}
          fluid
        />
        <Heading
          className="mb-2"
          as="h2"
          size="lg2"
          weight="bold"
          color="secondary"
        >
          Página não encontrada
        </Heading>
        <Text className="mb-5" size="lg1">
          Essa página não existe
        </Text>

        <div className="d-flex gap-3 mt-5">
          <Button
            variant="outline"
            size="lg"
            icon={faLeft}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
          <Button size="lg" icon={faHome} onClick={() => navigate('/')}>
            Início
          </Button>
        </div>
      </div>

      <SquareGraphic className={styles.graphic} />
    </MainTemplate>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => (
  <SEO
    title="Página não encontrada"
    description="O que você procura não está aqui"
  />
);
